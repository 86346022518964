<template>
  <div>
    <a-row :gutter="24">
      <a-col :span="14">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-descriptions
              title="申请状态"
              :column="1"
              class="simiot-descriptions simiot-descriptions-max-8"
            >
              <a-descriptions-item label="企业资质状态">
                {{ registeredAgent.status }}
              </a-descriptions-item>
              <a-descriptions-item label="账号注册时间">
                {{ registeredAgent.created_at }}
              </a-descriptions-item>
              <a-descriptions-item label="企业资质提交时间">
                {{ registeredAgent.last_submitted_at }}
              </a-descriptions-item>
              <a-descriptions-item label="审核时间">
                {{ registeredAgent.last_checked_at }}
              </a-descriptions-item>
            </a-descriptions>

            <a-divider />

            <a-descriptions
              title="基本信息"
              :column="1"
              class="simiot-descriptions  simiot-descriptions-max-8"
            >
              <a-descriptions-item label="客户名称">
                {{ registeredAgent.name }}
              </a-descriptions-item>
              <a-descriptions-item label="所在城市">
                {{ registeredAgent.region }}
              </a-descriptions-item>
              <a-descriptions-item label="登录账号">
                <div class="simiot-word-break-all">{{ registeredAgent.username }}</div>
              </a-descriptions-item>
              <a-descriptions-item label="联系对象">
                {{ registeredAgent.full_name }}
              </a-descriptions-item>
              <a-descriptions-item label="手机号码">
                {{ registeredAgent.phone_number }}
              </a-descriptions-item>
              <a-descriptions-item label="邮箱地址">
                {{ registeredAgent.email }}
              </a-descriptions-item>
              <a-descriptions-item label="责任销售">
                {{ registeredAgent.sale_full_name }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>

          <a-col :span="12">
            <a-descriptions
              title="企业工商信息"
              :column="1"
              class="simiot-descriptions  simiot-descriptions-max-8"
            >
              <a-descriptions-item label="企业全称">
                {{ registeredAgent.enterprise_name }}
              </a-descriptions-item>
              <a-descriptions-item label="营业执照注册号">
                {{ registeredAgent.registration_no }}
              </a-descriptions-item>
              <a-descriptions-item label="营业执照照片">
                <template v-if="registeredAgent.business_license_url">
                  <a class="tip-text" @click="handlePreview(registeredAgent.business_license_url)">点击查看图片</a>
                </template>
              </a-descriptions-item>
            </a-descriptions>

            <a-divider />

            <a-descriptions
              title="法人身份信息"
              :column="1"
              class="simiot-descriptions  simiot-descriptions-max-8"
            >
              <a-descriptions-item label="法人姓名">
                {{ registeredAgent.legal_name }}
              </a-descriptions-item>
              <a-descriptions-item label="法人身份证号">
                {{ registeredAgent.identity_card }}
              </a-descriptions-item>
              <a-descriptions-item label="身份证照片(正面)">
                <template v-if="registeredAgent.front_identity_card_url">
                  <a class="tip-text" @click="handlePreview(registeredAgent.front_identity_card_url)">点击查看图片</a>
                </template>
              </a-descriptions-item>
              <a-descriptions-item label="身份证照片(反面)">
                <template v-if="registeredAgent.back_identity_card_url">
                  <a class="tip-text" @click="handlePreview(registeredAgent.back_identity_card_url)">点击查看图片</a>
                </template>
              </a-descriptions-item>
            </a-descriptions>

            <a-divider />

            <a-descriptions
              title="终端设备信息"
              :column="1"
              class="simiot-descriptions  simiot-descriptions-max-8 simiot-descriptions-multiline"
            >
              <a-descriptions-item label="终端设备名称">
                {{ registeredAgent.device_name }}
              </a-descriptions-item>
              <a-descriptions-item label="终端设备照片">
                <template v-if="deviceList">
                  <div
                    v-for="(device, key) in deviceList"
                    :key="key"
                  >
                    <a
                      class="tip-text"
                      @click="handlePreview(device)"
                    >点击查看图片{{ key+1 }}
                    </a>
                  </div>
                </template>
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <a-col :span="24" v-if="isHasEditPermission" :style="{'text-align': 'center'}">
            <a-space>
              <a-button
                type="primary"
                @click="showPassModal()"
                v-if="registeredAgent.status === '待审核'"
              >
                通过
              </a-button>

              <a-button
                type="primary"
                @click="showNotPassModal()"
                v-if="registeredAgent.status === '待审核'"
              >
                未通过
              </a-button>

              <a-button
                type="primary"
                @click="showInvalidModal()"
                v-if="isShowInvalidButton"
              >
                无效账户
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-col>

      <a-col :span="10">
        <a-descriptions
          title="操作历史"
          :column="1"
          class="simiot-descriptions"
        />

        <operation-history-list
          :reference-id="this.registeredAgentId"
          reference-type="RegisteredAgent"
        />
      </a-col>
    </a-row>

    <preview-image
      :visible.sync="previewVisible"
      :image.sync="previewImage"
    />

    <invalid-modal
      v-if="isShowInvalidModal"
      :visible.sync="isShowInvalidModal"
      :id="this.registeredAgentId"
      @completed="invalid"
    />

    <not-pass-modal
      v-if="isShowNotPassModal"
      :visible.sync="isShowNotPassModal"
      :id="this.registeredAgentId"
      @completed="notPass"
    />

    <pass-modal
      v-if="isShowPassModal"
      :visible.sync="isShowPassModal"
      :id="this.registeredAgentId"
      @completed="pass"
    />
  </div>
</template>

<script>
import OperationHistoryList from '@/views/operation_histories'
import { findRegisteredAgent, findRegisteredAgentName } from '@/api/registered_agent'
import { hasPermission } from '@/utils/permission'
import PreviewImage from '@/components/PreviewImage'
export default {
  name: 'ShowRegisteredAgent',
  components: {
    OperationHistoryList,
    InvalidModal: () => import('@/views/registered_agents/Invalid'),
    NotPassModal: () => import('@/views/registered_agents/NotPass'),
    PassModal: () => import('@/views/registered_agents/Pass'),
    PreviewImage
  },
  data() {
    return {
      registeredAgent: {},
      deviceList: [],
      isShowInvalidModal: false,
      isShowNotPassModal: false,
      isShowPassModal: false,
      previewVisible: false,
      previewImage: ''
    }
  },
  computed: {
    registeredAgentId() {
      return parseInt(this.$route.params.id)
    },

    isHasEditPermission() {
      return hasPermission('registered_agents/edit')
    },

    isShowInvalidButton() {
      if (this.registeredAgent.status === '未提交' || this.registeredAgent.status === '待审核') {
        return true
      }
      return false
    }
  },
  created() {
    this.fetchData()
    this.setBreadcrumb()
  },
  methods: {
    setBreadcrumb() {
      findRegisteredAgentName(this.registeredAgentId).then((res) => {
        if (res.code === 0) {
          this.$store.dispatch('SetDynamicBreadCrumbsPlaceholder', { ':registered_agent_name': res.data.name })
        }
      })
    },

    showInvalidModal() {
      this.isShowInvalidModal = true
    },

    showNotPassModal() {
      this.isShowNotPassModal = true
    },

    showPassModal() {
      this.isShowPassModal = true
    },

    invalid() {
      this.$router.push({ name: 'registered_agents' })
    },

    pass() {
      this.$router.push({ name: 'registered_agents' })
    },

    notPass() {
      this.$router.push({ name: 'registered_agents' })
    },

    // 显示图片预览
    handlePreview(imageURL) {
      this.previewImage = imageURL
      this.previewVisible = true
    },

    fetchData() {
      findRegisteredAgent(this.registeredAgentId).then((res) => {
        if (res.code === 0) {
          this.registeredAgent = res.data
          this.deviceList = res.data.devices_urls
        }
      })
    }
  }
}
</script>
