import request from '@/utils/request'

// 查询注册分销商列表
export function findRegisteredAgents(params) {
  return request({
    url: `/registered_agents`,
    method: 'get',
    params: params
  })
}

// 查询注册分销商详情
export function findRegisteredAgent(registeredAgentId) {
  return request({
    url: `/registered_agents/${registeredAgentId}`,
    method: 'get'
  })
}

// 查询注册分销商名称
export function findRegisteredAgentName(registeredAgentId) {
  return request({
    url: `/registered_agents/${registeredAgentId}/name`,
    method: 'get'
  })
}

// 失效注册分销商账号
export function invalidRegisteredAgentAccount(registeredAgentId) {
  return request({
    url: `/registered_agents/${registeredAgentId}/invalid`,
    method: 'post'
  })
}

// 审核未通过
export function notPassRegisteredAgentAccount(registeredAgentId, data) {
  return request({
    url: `/registered_agents/${registeredAgentId}/not_pass`,
    method: 'post',
    data
  })
}

// 注册分销商操作通过时的编辑表单
export function findRegisteredAgentForm(registeredAgentId) {
  return request({
    url: `/registered_agents/${registeredAgentId}/form`,
    method: 'get'
  })
}

// 审核通过
export function passRegisteredAgentAccount(registeredAgentId, data) {
  return request({
    url: `/registered_agents/${registeredAgentId}/pass`,
    method: 'post',
    data
  })
}
